import { allowAdditionalProperties } from "~lib/type-utils/allowAdditionalProperties";
import { DOT_SEPARATOR } from "./constants";
import uniq from "lodash/uniq";

export type VenueLike = allowAdditionalProperties<{
  name: string;
  address: {
    city: string;
  };
  timezone: string;
  locale: string;
}>;

export function getEventVenueLocation(
  venue: VenueLike | VenueLike[] | undefined
) {
  if (!venue) return "";

  if (Array.isArray(venue)) {
    return uniq(venue.map(venueLocationToString)).join(` ${DOT_SEPARATOR} `);
  }

  return venueLocationToString(venue);
}

function venueLocationToString(venue: VenueLike) {
  if (!venue?.name) {
    return "";
  }

  if (!venue?.address?.city) {
    return venue?.name;
  }

  return `${venue?.name}, ${venue?.address?.city}`;
}
