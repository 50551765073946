import { atom } from "jotai";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import useIsGAInitialised from "~features/analytics/useIsGAInitialised";
import { Release } from "~graphql/fetchers";
import { MembershipQuery } from "~graphql/sdk";
import { PageName } from "~telemetry/enums/AttributeNames";

export const googleAnalyticsReleaseAtom = atom<Release | undefined>(undefined);
export const googleAnalyticsMembershipAtom = atom<
  MembershipQuery["membership"] | undefined
>(undefined);

export const useGoogleAnalyticsPageView = (
  fireEvent: boolean,
  pageName: PageName,
  eventId?: string
) => {
  const isGAInitialised = useIsGAInitialised();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      typeof window !== "undefined" &&
      isGAInitialised &&
      fireEvent
    ) {
      ReactGA.send({
        hitType: "pageview",
        event_id: eventId,
        page_name: pageName,
      });
    }
  }, [isGAInitialised, eventId, fireEvent]);
};
