import { ReactNode, useState } from "react";
import { Box, Flex, TransparentButton, Text, Stack } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";
import { css } from "styled-components";

import { Icon } from "~components";
import HeaderAlertBanner from "~components/common/common.HeaderAlertBanner";

const MotionBox = motion(Box);

interface BaseHeaderProps {
  title: ReactNode;
  onClickBack: () => void;
  subTitle?: ReactNode;
  backTxt?: string;
  children?: ReactNode;
  desktopMenuItems?: ReactNode;
  mobileMenuItems?: ReactNode;
}

export default function BaseHeader({
  title,
  subTitle,
  backTxt,
  onClickBack,
  children,
  desktopMenuItems,
  mobileMenuItems,
}: BaseHeaderProps) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Box
        position="fixed"
        zIndex="sticky"
        top={0}
        width="100%"
        bg="white"
        boxShadow={"sm"}
      >
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          px={3}
          py="6/4"
        >
          <Flex flex={1}>
            {onClickBack && (
              <>
                <Box d={{ _: "none", md: "flex" }} mr={3}>
                  <TransparentButton
                    css={css({
                      transition: "opacity 0.2s",
                      "&:hover": {
                        opacity: "0.7",
                      },
                    })}
                    onClick={onClickBack}
                  >
                    <Icon icon="backspace" color="N600" fontSize={6} mr="3/4" />
                    {backTxt && (
                      <Text
                        color="N600"
                        fontSize={3}
                        fontWeight="extraBold"
                        lineHeight="medium"
                      >
                        {backTxt}
                      </Text>
                    )}
                  </TransparentButton>
                </Box>
                <Box d={{ _: "flex", md: "none" }} mr="6/4">
                  <TransparentButton onClick={onClickBack}>
                    <Icon icon="chevron-left" />
                  </TransparentButton>
                </Box>
              </>
            )}
            <Flex
              borderLeft={{ _: "none", md: "1px" }}
              borderLeftColor={{ _: "N200", md: "N200" }}
              flexDir="column"
              pl={{ _: 0, md: 2 }}
              minW={{ _: "216px", sm: "auto" }}
              w={{ _: "216px", sm: "auto" }}
              flex={1}
            >
              <Text
                color="N800"
                fontSize={4}
                fontWeight="heavy"
                lineHeight="normal"
                mb="1/2"
                textTransform="uppercase"
                ellipsis
              >
                {title}
              </Text>
              {subTitle && (
                <Text
                  fontSize={2}
                  lineHeight="medium"
                  fontWeight={{ _: "demiBold", md: "heavy" }}
                  d="flex"
                  // @ts-expect-error known
                  color={{ _: "N700", md: "S300" }}
                >
                  {subTitle}
                </Text>
              )}
            </Flex>
          </Flex>
          {mobileMenuItems && (
            <Box
              d={{ _: "flex", md: "none" }}
              mr={{ _: 0, md: 2 }}
              position="relative"
            >
              <TransparentButton onClick={() => setShowMenu(true)}>
                <Icon icon="more" color="P300" fontSize={5} />
              </TransparentButton>
              <AnimatePresence>
                {showMenu && (
                  <OutsideClickHandler
                    onOutsideClick={() => setShowMenu(false)}
                  >
                    <MotionBox
                      position="absolute"
                      top={0}
                      right={0}
                      borderRadius="md"
                      initial={{ opacity: 0, width: 0 }}
                      exit={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: "auto" }}
                      p={2}
                      backgroundColor="white"
                      boxShadow={
                        "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)" as any
                      }
                    >
                      <Stack flexDir="column" alignItems="flex-start" gap={2}>
                        {/* Mobile */}
                        {mobileMenuItems}
                      </Stack>
                    </MotionBox>
                  </OutsideClickHandler>
                )}
              </AnimatePresence>
            </Box>
          )}
          {desktopMenuItems && (
            <Box d={{ _: "none", md: "flex" }}>
              {/* Desktop */} {desktopMenuItems}
            </Box>
          )}
          {children}
        </Flex>
        <HeaderAlertBanner />
      </Box>
    </>
  );
}
