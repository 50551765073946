import { v4 as uuidv4 } from "uuid";

export default function getSessionId() {
  const hasSessionStorage = typeof sessionStorage !== "undefined";

  // If there is no session storage available, just return a new uuid
  if (!hasSessionStorage) {
    return uuidv4();
  }

  try {
    // If there is session storage, check if there is a session id already
    const existingSessionId = sessionStorage.getItem("sessionId");
    if (existingSessionId) {
      return existingSessionId;
    }

    // If there is no session id, create a new one and store it in session storage
    const newSessionId = uuidv4();
    sessionStorage.setItem("sessionId", newSessionId);
    return newSessionId;
  } catch (err) {
    // If there was an error accessing sessionStorage return a new uuid.
    return uuidv4();
  }
}
