export enum AttributeNames {
  FLICKET_USER_INTERACTION_TYPE = "flicket.user_interaction_type",
  FLICKET_USER_INTERACTION_NAME = "flicket.user_interaction_name",
  FLICKET_SESSION_ID = "flicket.session_id",
  FLICKET_ORG_ID = "flicket.org_id",
  FLICKET_FORWARD_TO_NEST = "flicket.foward_to_nest",
  FLICKET_EVENT_ID = "flicket.event_id", // The ID of the row in the "event" table. Not the same as the "event" that is being tracked.
  FLICKET_TIMEZONE = "flicket.timezone",
  FLICKET_USER_AGENT = "flicket.user_agent",
  FLICKET_COMPETITION_ID = "flicket.competition_id",
  FLICKET_EVENT_PHASE = "flicket.event_phase", // FlicketEventPhase: "registration" | "onsale";
  FLICKET_EVENT_DETAILS_PAGE_STATE = "flicket.event_details_page_state",

  FLICKET_TICKET_TYPE_ID = "flicket.ticket_type.id",
  FLICKET_TICKET_TYPE_NAME = "flicket.ticket_type.name",
  FLICKET_TICKET_ID = "flicket.ticket.id",
  FLICKET_ZONE_ID = "flicket.zone.id",
  FLICKET_ZONE_NAME = "flicket.zone.name",
  HTTP_URL = "http.url",

  IS_MEMBERSHIP = "flicket.is_membership",
  IS_SEATED = "flicket.is_seated",
  BUTTON_LABEL = "button_label",
}

export enum PageName {
  EVENT_DETAILS_PAGE = "event-details-page",
  EVENT_WAITLIST_PAGE = "event-waitlist-page",
  EVENT_TICKET_WAITLIST_PAGE = "event-ticket-waitlist-page",
  EVENT_RESERVATION_PAGE = "event-reservation-page",
}

export enum InteractionNames {
  BUTTON_PRIMARY = "button_primary",
  BUTTON_SECONDARY = "button_secondary",
  BUTTON_TERTIARY = "button_tertiary",
}
