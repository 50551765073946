import { Attributes, trace } from "@opentelemetry/api";
import { useEffect } from "react";
import { useOrganization } from "~hooks";
import getSessionId from "~lib/helpers/sessionId";
import { TRACER_NAME } from "~telemetry/FrontendTracer";
import { AttributeNames, PageName } from "~telemetry/enums/AttributeNames";

export default function usePageLoad(
  pageName: PageName,
  {
    forwardToNest = undefined,
    eventId,
    attributes = {},
  }: { forwardToNest?: boolean; eventId?: string; attributes?: Attributes },
  fireEvent: boolean | undefined = true
) {
  const { organization, hasFeature } = useOrganization();

  useEffect(() => {
    // An escape hatch for now to turn off page load events
    if (hasFeature("disablePageLoadTracing")) return;

    // The actual event will only fire when fireEvent is true.
    if (!fireEvent) return;

    const sessionId = getSessionId();

    // Create a span for the page activity
    const pageSpan = trace.getTracer(TRACER_NAME).startSpan("page-load", {
      attributes: {
        [AttributeNames.FLICKET_USER_INTERACTION_TYPE]: "page-load",
        [AttributeNames.FLICKET_USER_INTERACTION_NAME]: pageName,
        [AttributeNames.FLICKET_ORG_ID]: organization.id,
        [AttributeNames.FLICKET_EVENT_ID]: eventId,
        [AttributeNames.FLICKET_SESSION_ID]: sessionId,
        [AttributeNames.FLICKET_FORWARD_TO_NEST]: forwardToNest,
        [AttributeNames.HTTP_URL]: window.location.href,
        [AttributeNames.FLICKET_TIMEZONE]: new window.Intl.DateTimeFormat().resolvedOptions()
          .timeZone,
        ...attributes,
      },
    });
    pageSpan.end();
  }, [fireEvent]);
}
